<template>
  <div></div>
</template>

<script>
import useJwt from '@core/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
    this.logout();
  },
  methods: {
    logout() {
      try {
        console.log("logout")
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        this.$ability.update(initialAbility)

        this.$auth.logout();
      } catch (error) {
        console.log(error)

        this.$auth.logout();
      }

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
